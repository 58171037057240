<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Buat Tagihan Mahasiswa Baru</h2>
      <div
        class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
      >
        <router-link
          tag="a"
          class="btn btn-success w-50 mt-3 mb-2"
          :to="{ name: 'validasi-transaksi-pmb' }"
        >
          <CheckCircleIcon class="w-5 mr-1" />
          Validasi Pembayaran MABA
        </router-link>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div v-if="loading" class="block-loading">
          <LoadingIcon icon="three-dots" class="w-8 h-8" />
          <div class="text-center text-xs mt-2">
            Loading data... Please Wait...
          </div>
        </div>
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="grid grid-cols-12 gap-3 p-3">
            <div class="col-span-12 xl:col-span-8">
              <div class="grid grid-cols-12 gap-2">
                <div class="col-span-12 xl:col-span-2">
                  <label class="form-label">Angkatan</label>
                  <select v-model="angkatan" class="form-select sm:mr-2 mb-2">
                    <option
                      v-for="smtr in semester"
                      :key="smtr.id"
                      :value="smtr.value"
                    >
                      {{ smtr.value }}
                    </option>
                  </select>
                </div>

                <div class="col-span-12 xl:col-span-8">
                  <label class="form-label">Calon Mahasiswa</label>
                  <TailSelect
                    v-model="camaSelected"
                    :options="{
                      search: true,
                      classNames: 'w-full',
                      deselect: true,
                    }"
                  >
                    <option
                      v-for="list in listCama"
                      :key="list.id_ujian"
                      :value="list.id_ujian"
                    >
                      {{ list.nm_pd }} ({{ list.no_ujian }})
                    </option>
                  </TailSelect>
                </div>
                <div class="col-span-12 xl:col-span-2">
                  <label class="form-label">Total Lulus Tes</label>
                  <input
                    v-model="totalLulus"
                    type="text"
                    class="form-control"
                    placeholder="Total Lulus"
                    readonly
                  />
                </div>

                <div class="col-span-12">
                  <div class="mt-1">
                    <Alert type="alert-danger" :dismissable="true"
                      >Pastikan status HASIL UJIAN, PRODI, dsb. dari calon
                      mahasiswa telah diset LULUS oleh BAA atau BKP. Baik JTT
                      maupun JT</Alert
                    >

                    <Alert
                      v-if="errorbiayaKuliah != null"
                      :type="'alert-danger'"
                      :dismissable="true"
                      >{{ errorbiayaKuliah }}</Alert
                    >

                    <Alert
                      v-if="statusSimpan.status != null"
                      :type="
                        statusSimpan.status ? 'alert-success' : 'alert-danger'
                      "
                      :dismissable="true"
                      >{{ statusSimpan.msg }}</Alert
                    >
                    <Alert
                      v-if="statusGetTagihan.status != null"
                      :type="
                        statusGetTagihan.status
                          ? 'alert-success'
                          : 'alert-danger'
                      "
                      :dismissable="true"
                      >{{ statusGetTagihan.msg }}</Alert
                    >
                    <Alert
                      v-if="statusHapus.status != null"
                      :type="
                        statusHapus.status ? 'alert-success' : 'alert-danger'
                      "
                      :dismissable="true"
                      >{{ statusHapus.msg }}</Alert
                    >
                  </div>

                  <router-link
                    tag="a"
                    class="btn btn-warning btn-sm w-50 mb-2"
                    :to="{ name: 'master-keuangan' }"
                  >
                    <DatabaseIcon class="w-4 mr-2" />
                    Master Biaya
                  </router-link>
                  <button
                    class="btn btn-default btn-sm ml-2"
                    @click="
                      () => {
                        panelOpen = true;
                      }
                    "
                  >
                    <CreditCardIcon class="w-4 mr-2" />
                    Skema Cicilan
                  </button>
                </div>
              </div>
            </div>

            <div
              class="col-span-12 xl:col-span-4 bg-gray-300 dark:bg-gray-700 pb-3"
            >
              <div class="p-3">
                <div class="grid grid-cols-2 gap-2">
                  <div class="col-span-1">
                    <label class="form-label">Sub Comp</label>
                    <TailSelect
                      v-model="model.subcomp"
                      :options="{
                        search: true,
                        classNames: 'w-full',
                        deselect: true,
                      }"
                    >
                      <option
                        v-for="list in subcomps"
                        :key="list.Kode"
                        :value="list.Kode"
                      >
                        {{ list.Keterangan }} ({{ list.Kode }})
                      </option>
                    </TailSelect>
                  </div>
                  <div class="col-span-1">
                    <label class="form-label">Produk</label>

                    <TailSelect
                      v-model="model.produk"
                      :options="{
                        search: true,
                        classNames: 'w-full',
                        deselect: true,
                      }"
                    >
                      <option
                        v-for="list in produks"
                        :key="list.kode"
                        :value="list.kode"
                      >
                        {{ list.Keterangan }} ({{ list.kode }})
                      </option>
                    </TailSelect>
                  </div>
                </div>

                <label class="form-label mt-3">Bank</label>
                <select v-model="model.bank" class="form-select">
                  <option
                    v-for="(item, index) in banks"
                    :key="index"
                    :value="item.kode"
                  >
                    {{ item.Keterangan }}
                  </option>
                </select>
              </div>

              <button
                :disabled="totalHarusDiupload == 0"
                type="button"
                class="btn btn-success w-50 ml-3 mr-2"
                @click="buatTagihan"
              >
                Kirim Tagihan
              </button>

              <a
                v-if="dataCama?.no_ujian"
                target="_blank"
                :href="
                  apiurl + 'cetaksuratpernyataanmaba/' + dataCama?.no_ujian
                "
                class="btn btn-warning w-50 ml-3 mr-2"
              >
                Pernyataan
              </a>
              <a
                v-if="dataCama?.no_ujian"
                target="_blank"
                :href="
                  apiurl +
                  'cetaksuratpernyataanmaba_lunas/' +
                  dataCama?.no_ujian
                "
                class="btn btn-danger w-50 ml-3 mr-2"
              >
                LUNAS
              </a>
              <a
                v-if="dataCama?.no_ujian"
                target="_blank"
                :href="apiurl + 'cetakloa/' + dataCama?.no_ujian"
                class="btn btn-primary w-50 ml-3 mr-5"
              >
                Cetak LOA
              </a>
            </div>
          </div>
          <FormCreateTagihan
            v-if="camaSelected"
            class="p-2"
            :model="model"
            :id-ujian="camaSelected"
            :mhs="dataCama"
            :angkatan="angkatan"
            :jenis-biaya-list="jenisBiayaList"
            @dataTagihan="setDataTagihan"
            @loadingBlock="setLoadingBlock"
          />
        </div>
      </div>
    </div>

    <SlideOver :open="panelOpen" size="max-w-6xl" @close="closePanel">
      <template #title>Skema Cicilan Pembayaran</template>
      <div class="h-full" aria-hidden="true">
        <SkemaCicilan />
      </div>
    </SlideOver>

    <ModalDialogPositive
      :id="'buat-tagihan'"
      :question="'Anda yakin ingin membuat Tagihan PMB untuk calon mahasiswa?'"
    >
      <template #delete-button>
        <button type="button" class="btn btn-success w-24" @click="submit">
          Ya
        </button>
      </template>
    </ModalDialogPositive>
  </div>
</template>

<script>
import { option } from "@/utils/listoptions";
import { HTTPKEU } from "../../../services/http";
import Alert from "../../../components/alerts/Alert";
import ModalDialogPositive from "../../../components/utility/ModalDialogPositive";
import FormCreateTagihan from "./FormCreateTagihan";
import SlideOver from "../../../layouts/main/SlideOver";
import SkemaCicilan from "./SkemaCicilan";
export default {
  name: "Form",
  components: {
    SkemaCicilan,
    SlideOver,
    FormCreateTagihan,
    ModalDialogPositive,
    Alert,
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      listMhsAktif: [],
      listTagihanJupiter: [],
      angkatan: new Date().getFullYear(),
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,
      jenisBiayaList: null,
      id_jenis_biaya: ["2"],
      jenisBiayaSelected: [],
      model: {
        bank: "BCA",
        produk: "010",
        subcomp: null,
        id_smt: null,
      },
      biayaKuliah: null,
      errorbiayaKuliah: null,
      statusSimpan: {
        msg: null,
        status: null,
      },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusHapus: {
        msg: null,
        status: null,
      },
      loading: false,
      biayaAdm: 3000,
      biaya: [],
      listBiayaMhs: [],
      biayatagihanid: null,
      showtable: false,
      jalur: "A",
      sesi: 1,
      listCama: [],
      camaSelected: null,
      dataCama: null,
      dataTagihan: null,
      isCicil: false,
      apiurl: process.env.VUE_APP_KEUANGAN,
      panelOpen: false,
      totalLulus: 0,
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear() + 2;
      let years = [];
      for (year; year >= 2019; year--) {
        years.push({ id: year, value: year });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    listBuatTagihan() {
      return this.listBiayaMhs.filter((f) => f.buat_tagihan === true);
    },

    totalHarusDiupload() {
      let total = 0;
      if (this.dataTagihan?.tagihSumbangan) {
        for (let s of this.dataTagihan.tagihSumbangan.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.dataTagihan?.tagihUk1 && this.isCicil === false) {
        for (let s of this.dataTagihan.tagihUk1.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.dataTagihan?.tagihUk2 && this.isCicil === false) {
        for (let s of this.dataTagihan.tagihUk2.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }

      if (this.dataTagihan?.tagihUk && this.isCicil) {
        for (let s of this.dataTagihan.tagihUk.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }

      return total;
    },
  },

  watch: {
    id_jenis_biaya: {
      handler: function (h) {
        console.log(h);
        this.biayaKuliahDetail();
      },
      deep: true,
    },
    camaSelected: {
      handler: function (h) {
        this.dataCama = this.listCama.find((a) => {
          return a.id_ujian === h;
        });
        this.dataCama.no_va = this.dataCama.no_ujian;
        let p = this.dataCama.pilfix;
        this.dataCama.prodiFix = this.listOptionsState?.prodi.find((f) => {
          return f?.kode_angka == p;
        });
      },
      deep: true,
    },
    angkatan: {
      handler: function (h) {
        console.log(h);
        this.cariData();
      },
      deep: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi();
    this.getBankList();
    this.getProdukList();
    this.getSubcomList();
  },
  mounted() {
    //this.setJenisBiayaSelected();
    this.getJenisBiayaList();
    this.cariData();
  },
  methods: {
    selectCama(curr, id) {
      this.camaSelected = id;
    },
    selectProdi(curr, id) {
      this.smsSelected = id;
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
    },
    reset() {
      this.listMhsAktif = [];
      this.biayaAdm = 3000;
      this.listTagihanJupiter = [];
      this.biaya = [];
    },
    cariData() {
      this.loading = true;
      HTTPKEU.post("list_calon_mhs_lulus_all", {
        angkatan: this.angkatan,
      })
        .then((res) => {
          this.listCama = res.data;
          this.totalLulus = res.data.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
        let prdpmb = ["00009", "00010", "00028"];
        this.subcomps = this.subcomps.filter((f) => {
          return prdpmb.includes(f.Kode);
        });
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
        let prdpmb = [
          "001",
          "002",
          "003",
          "010",
          "011",
          "012",
          "022",
          "023",
          "028",
          "029",
          "030",
          "031",
        ];
        this.produks = this.produks.filter((f) => {
          return prdpmb.includes(f.kode);
        });
      });
    },
    getBankList() {
      HTTPKEU.get("list/bank").then((res) => {
        this.banks = res.data;
      });
    },
    getBiayaADM() {
      return HTTPKEU.post("master/biayaAdm", {
        tahun: this.smtSelected?.substr(0, 4),
      });
    },
    getJenisBiayaList() {
      HTTPKEU.get("list/jenisbiaya")
        .then((res) => {
          this.jenisBiayaList = res.data;
        })
        .finally(() => {
          //this.setJenisBiayaSelected();
        });
    },
    submit() {
      this.statusSimpan.msg = null;
      this.statusSimpan.status = null;
      if (this.model.subcomp != null || this.model.produk != null) {
        this.loading = true;
        console.log(this.dataTagihan);
        let data_pemb = [...this.dataTagihan.tagihSumbangan];
        if (this.isCicil && this.dataTagihan?.tagihUk?.length > 0) {
          data_pemb = [
            ...this.dataTagihan.tagihSumbangan,
            ...this.dataTagihan.tagihUk,
          ];
        }

        if (this.isCicil === false) {
          data_pemb = [
            ...this.dataTagihan.tagihSumbangan,
            ...this.dataTagihan.tagihUk1,
            ...this.dataTagihan.tagihUk2,
          ];
        }

        HTTPKEU.post("tagihan/simpan_tagihan_pmb", {
          subcomp: this.model.subcomp,
          bank: this.model.bank,
          produk: this.model.produk,
          id_smt: this.dataCama.smt,
          id_daftar: this.dataCama.id_daftar,
          id_ujian: this.dataCama.id_ujian,
          nominal_tagih: this.totalHarusDiupload,
          data_pembiayaan: data_pemb,
        })
          .then((res) => {
            if (res.status === 200) {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = true;
            } else {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = false;
            }
          })
          .catch((err) => {
            this.statusSimpan.msg = err.response?.data?.message;
            this.statusSimpan.status = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.statusSimpan.msg = "Pilih SubComp dan Produk terlebih dahulu";
        this.statusSimpan.status = false;
      }

      cash("#buat-tagihan").modal("hide");
    },
    buatTagihan() {
      cash("#buat-tagihan").modal("show");
    },
    setDataTagihan(data, cicil) {
      this.dataTagihan = data;
      this.isCicil = cicil;
    },
    setLoadingBlock(loading) {
      this.loading = loading;
    },
    closePanel() {
      this.panelOpen = false;
    },
  },
};
</script>
